<template>
  <v-container>
    <v-expansion-panels v-model="panel">
      <v-expansion-panel ref="panel1">
        <v-expansion-panel-header>
          <h2>Technology Search</h2>
        </v-expansion-panel-header>
        <v-expansion-panel-content eager>
          <TechForm @panel="panel = null" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-row>
      <v-col v-if="$store.getters['technologies']">
        <v-card :elevation="0">
          <v-card-title><h3>Technology Catalog</h3></v-card-title>
          <v-row justify="end" class="pa-8">
            <v-btn
              @click="scrollToPanel('panel1')"
              style="background-color: #2ba8e0"
              >Go back to Search</v-btn
            >
          </v-row>
          <TechTable />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TechForm from "./Form";
import TechTable from "./Table";

export default {
  // eslint-disable-next-line
  name: "Catalogue",
  components: {
    TechForm,
    TechTable,
  },
  methods: {
    scrollToPanel(panelRef) {
      this.panel = 0;
      this.$refs[panelRef].$el.scrollIntoView({ behavior: "smooth" });
    },
  },
  data: () => ({
    panel: 0,
  }),
};
</script>

<style scoped>
::v-deep .v-icon {
  color: #2ba8e0 !important;
}
</style>
