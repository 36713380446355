<template>
  <v-card dense flat tile>
    <v-card-subtitle id="economics"
      ><b>{{ startCase(category) }}</b></v-card-subtitle
    >
    <v-divider></v-divider>
    <v-container>
      <v-row>
        <v-card-text>
          <v-list-item>
            <v-list-item-content>
              <v-col
                ><v-list-item-title>Cost:</v-list-item-title>
                <v-list-item-content>{{
                  data.cost || "Unknown"
                }}</v-list-item-content></v-col
              >
              <v-col>
                <v-list-item-title>Efficiency:</v-list-item-title>
                <v-list-item-content>{{
                  data.efficiency || "Unknown"
                }}</v-list-item-content>
              </v-col>
              <v-col v-if="data.efficiency || data.cost">
                <v-list-item-title>Notes:</v-list-item-title>
                <v-list-item-content>{{ data.notes }}</v-list-item-content>
              </v-col>
            </v-list-item-content>
          </v-list-item>
        </v-card-text>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import startCase from "lodash/startCase";

export default {
  // eslint-disable-next-line
  name: "Economics",
  props: ["data", "category"],
  data: () => ({
    startCase: startCase,
  }),
};
</script>

<style scoped>
#economics {
  font-size: 1rem;
}

.v-list-item__title {
  font-weight: 475 !important;
  font-size: 0.875rem !important;
  padding: 0.25rem 0 !important;
}

.v-list-item__content {
  padding: 0 !important;
}

.v-card__subtitle {
  padding: 16px 16px 0 16px !important;
}
</style>
