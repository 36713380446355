<template>
  <v-card dense flat tile>
    <v-card-subtitle id="parameters"
      ><b>{{ startCase(category) }}</b></v-card-subtitle
    >
    <v-divider></v-divider>
    <v-container>
      <v-row>
        <v-col>
          <v-card-text>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Power Rating (kW)</v-list-item-title>
                <v-list-item-content
                  >Max:
                  {{
                    data.power_rating.power_rating_max * 1 || "Unknown"
                  }}</v-list-item-content
                >
                <v-list-item-content
                  >Min:
                  {{
                    data.power_rating.power_rating_min * 1 || "Unknown"
                  }}</v-list-item-content
                >
              </v-list-item-content>
            </v-list-item>
          </v-card-text>
        </v-col>
        <v-col>
          <v-card-text>
            <v-list-item>
              <v-list-item-content>
                <template v-if="type.name == 'Hydrokinetic'">
                  <v-list-item-title>Water Depth (ft)</v-list-item-title>
                  <v-list-item-content
                    >Max:
                    {{
                      data.head.head_max * 1 || "Unknown"
                    }}</v-list-item-content
                  >
                  <v-list-item-content
                    >Min:
                    {{
                      data.head.head_min * 1 || "Unknown"
                    }}</v-list-item-content
                  ></template
                >
                <template v-else>
                  <v-list-item-title>Head (ft)</v-list-item-title>
                  <v-list-item-content
                    >Max:
                    {{
                      data.head.head_max * 1 || "Unknown"
                    }}</v-list-item-content
                  >
                  <v-list-item-content
                    >Min:
                    {{
                      data.head.head_min * 1 || "Unknown"
                    }}</v-list-item-content
                  >
                </template>
              </v-list-item-content>
            </v-list-item>
          </v-card-text>
        </v-col>
        <v-col>
          <v-card-text>
            <v-list-item>
              <v-list-item-content>
                <template v-if="type.name == 'Hydrokinetic'">
                  <v-list-item-title>Flow Rate (ft/s)</v-list-item-title>
                  <v-list-item-content
                    >Max:
                    {{
                      data.flow_rate.flow_max * 1 || "Unknown"
                    }}</v-list-item-content
                  >
                  <v-list-item-content
                    >Min:
                    {{
                      data.flow_rate.flow_min * 1 || "Unknown"
                    }}</v-list-item-content
                  >
                </template>
                <template v-else>
                  <v-list-item-title>Flow (cfs)</v-list-item-title>
                  <v-list-item-content
                    >Max:
                    {{
                      data.flow_rate.flow_max * 1 || "Unknown"
                    }}</v-list-item-content
                  >
                  <v-list-item-content
                    >Min:
                    {{
                      data.flow_rate.flow_min * 1 || "Unknown"
                    }}</v-list-item-content
                  >
                </template>
              </v-list-item-content>
            </v-list-item>
          </v-card-text>
        </v-col>
        <v-col>
          <v-card-text>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Grid (Volts)</v-list-item-title>
                <v-list-item-content
                  >Voltage: <br />
                  {{ data.voltage.voltage || "Unknown" }}</v-list-item-content
                >
              </v-list-item-content>
            </v-list-item>
          </v-card-text>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import startCase from "lodash/startCase";

export default {
  // eslint-disable-next-line
  name: "Category",
  props: ["data", "category", "type"],
  data: () => ({
    startCase: startCase,
  }),
};
</script>

<style scoped>
#parameters {
  font-size: 1rem;
}

.v-card__subtitle {
  padding: 16px 16px 0 16px !important;
}

.v-list-item__title {
  font-weight: 475 !important;
  font-size: 0.875rem !important;
  padding: 0.25rem 0 !important;
}

.v-list-item__content {
  padding: 0 !important;
}

.expanded-content {
  background-color: rgba(141, 195, 64, 0.35) !important;
}

.expanded-content tr:hover {
  background-color: transparent !important;
}
</style>
