<template>
  <v-app>
    <v-app-bar app absolute color="black">
      <v-container>
        <v-img max-width="225px" src="./assets/INL-Logo_Left-White.png"></v-img>
      </v-container>
      <v-container
        style="
          display: flex;
          align-items: baseline;
          justify-content: right;
          padding-top: 2rem;
        "
      >
        <v-icon style="padding-right: 0.5rem"> mdi-theme-light-dark </v-icon>
        <v-switch v-model="dark" color="blue" />
      </v-container>
    </v-app-bar>
    <v-main>
      <v-container>
        <v-row align="center" justify="center">
          <v-col class="introduction">
            <v-row>
              <v-col>
                <h1>Hydropower Technology Catalog</h1>
              </v-col>
            </v-row>
            <hr />
            <br />
            <p>
              Novel turbine technologies continue to be developed for the
              modernization of hydropower. These turbine technologies vary
              significantly in terms of form, fit, and function. The goal of the
              technology database is to create an interface that helps users
              determine a turbine technology's use as well as understand
              requirements for implementation on an existing system or
              complementary components or structures required for new
              developments. The database informs users of specific elements that
              a turbine technology includes, as well as what it does not
              include. This allows users to understand site specific
              considerations that will be necessary compliments to the selected
              turbine technology.
            </p>
          </v-col>
          <v-col>
            <v-row class="pa-5">
              <v-col>
                <a href="https://inl.gov" target="_blank">
                  <v-img
                    contain
                    width="125px"
                    height="125px"
                    src="./assets/inl-stacked.png"
                    class="ma-auto"
                  ></v-img>
                </a>
              </v-col>
              <v-col>
                <a href="https://pnnl.gov" target="_blank">
                  <v-img
                    contain
                    width="125px"
                    height="125px"
                    src="./assets/pnnl/PNNL_ABBREV_GREY.svg"
                    class="ma-auto"
                  ></v-img>
                </a>
              </v-col>
              <v-col>
                <a
                  href="https://www.energy.gov/eere/water/water-power-technologies-office"
                  target="_blank"
                >
                  <v-img
                    contain
                    width="275px"
                    height="100px"
                    src="./assets/doe-wpto-dark.png"
                    class="ma-auto"
                  ></v-img>
                </a>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
      <Catalogue />
    </v-main>
    <v-footer sticky>
      <v-row class="ma-auto">
        <v-btn small href="mailto:trevor.atkinson@inl.gov">
          Research Contact
        </v-btn>
        <v-btn small href="mailto:nathan.woodruff@inl.gov">
          Site Administrator
        </v-btn>
        <v-btn
          small
          target="_blank"
          href="https://doe.responsibledisclosure.com/hc/en-us"
          >Vulnerability Disclosure Program</v-btn
        >
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import Catalogue from "./components/Catalogue";
import { mdiThemeLightDark } from "@mdi/js";

export default {
  name: "App",
  components: {
    Catalogue,
  },
  data: () => ({
    dark: true,
    path: mdiThemeLightDark,
  }),
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },
  watch: {
    dark() {
      if (this.dark) {
        this.$vuetify.theme.dark = true;
      } else {
        this.$vuetify.theme.dark = false;
        this.$vuetify.theme.themes.light.background = "#4caf50";
      }
    },
  },
};
</script>

<style scoped>
::v-deep .v-toolbar__content {
  border-bottom: 5px #07519e solid !important;
}

i.v-icon.v-icon {
  color: white;
}

::v-deep .theme--light.v-input--switch .v-input--switch__track {
  color: white;
}

body {
  white-space: nowrap;
}

.introduction {
  padding: 1rem;
}

.introduction > p {
  font-size: 1rem;
}

.v-footer p {
  margin: 0 auto;
  font-size: 0.75rem;
}
</style>
