import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    form: {
      power_rating_min: null,
      power_rating_max: null,
      head_min: null,
      head_max: null,
      flow_min: null,
      flow_max: null,
      voltage: null,
      type: null,
      application: null,
    },
    technologies: null,
  },
  getters: {
    form: (state) => state.form,
    technologies: (state) => state.technologies
  },
  mutations: {
    power_rating_min(state, rating) {
      state.form.power_rating_min = rating;
    },
    power_rating_max(state, rating) {
      state.form.power_rating_max = rating;
    },
    head_min(state, head) {
      state.form.head_min = head;
    }, 
    head_max(state, head) {
      state.form.head_max = head;
    },
    flow_min(state, flow) {
      state.form.flow_min = flow;
    },
    flow_max(state, flow) {
      state.form.flow_max = flow;
    },
    voltage(state, voltage) {
      state.form.voltage = voltage;
    },
    type(state, type) {
      state.form.type = type;
    }, 
    application(state, application) {
      state.form.application = application;
    },
    technologies(state, technologies) {
      state.technologies = technologies;
    }
  },
  actions: {
  },
  modules: {
  }
})
