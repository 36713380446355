<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col>
        <v-card tile :width="width"
          ><v-card-title>
            {{ item.details.name }}
          </v-card-title>
          <v-card-subtitle>Technical Specifications</v-card-subtitle>
          <v-card-title>
            <v-row style="padding-bottom: 1rem">
              <v-col cols style="min-width: 325px">
                <TechnicalSpecs
                  :data="item.details.operational_parameters"
                  :type="item.type"
                  category="Operational Parameters"
                />
                <Economics
                  :data="item.details"
                  category="Economic Parameters"
                />
              </v-col>
              <v-col cols="5" class="hidden-md-and-down">
                <template v-if="image">
                  <v-card ref="image">
                    <v-card-subtitle>Preview</v-card-subtitle>
                    <v-card-text>
                      <v-container>
                        <v-img
                          contain
                          width="450px"
                          v-on:error="onImageError"
                          :src="image"
                        ></v-img>
                      </v-container>
                    </v-card-text>
                  </v-card>
                </template>
                <template v-else-if="image === false">
                  <v-card ref="image">
                    <v-card-subtitle>Preview</v-card-subtitle>
                    <v-card-text>
                      <v-container> Image Unavailable </v-container>
                    </v-card-text>
                  </v-card>
                </template>
              </v-col>
            </v-row>
          </v-card-title>
        </v-card>
        <v-expansion-panels accordion>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <template v-slot:actions>
                <v-icon class="icon">$expand</v-icon>
              </template>
              <span class="header">Water Conveyance</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content eager>
              <Category
                :data="item.details.water_conveyance"
                category="Water Conveyance"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header
              ><template v-slot:actions>
                <v-icon class="icon">$expand</v-icon>
              </template>
              <span class="header">Power Train</span></v-expansion-panel-header
            >
            <v-expansion-panel-content eager>
              <Category
                :data="item.details.power_train"
                category="Power Train"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header
              ><template v-slot:actions>
                <v-icon class="icon">$expand</v-icon>
              </template>
              <span class="header">Grid</span></v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <Category :data="item.details.grid" category="Grid" />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <template v-slot:actions>
                <v-icon class="icon">$expand</v-icon>
              </template>
              <span class="header">Auxiliary Support Components</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content eager>
              <Category
                :data="item.details.auxiliary_support_components"
                category="Auxiliary Support Components"
              />
            </v-expansion-panel-content>
          </v-expansion-panel>
          <template v-if="item.details.spec_sheet">
            <v-expansion-panel>
              <v-expansion-panel-header expand-icon="">
                <template v-slot:actions>
                  <v-icon class="icon">$expand</v-icon>
                </template>
                <span class="header">Specification Sheet</span>
              </v-expansion-panel-header>
              <v-expansion-panel-content eager>
                <v-row>
                  <v-col style="text-align: center">
                    <template>
                      <template
                        v-if="
                          $vuetify.breakpoint.md ||
                          $vuetify.breakpoint.sm ||
                          $vuetify.breakpoint.xs
                        "
                      >
                        <v-row>
                          <v-col style="padding: 3rem !important">
                            <p>
                              <a
                                :href="item.details.spec_sheet"
                                target="_blank"
                                rel="noreferrer"
                                >Go to Spec Sheet</a
                              >
                            </p>
                          </v-col>
                        </v-row>
                      </template>
                      <template v-else>
                        <object
                          :data="item.details.spec_sheet"
                          :height="this.$vuetify.breakpoint.height"
                          :width="this.$vuetify.breakpoint.width / 2"
                        ></object>
                      </template>
                    </template>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </template>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Category from "./Category";
import TechnicalSpecs from "./TechnicalSpecs";
import Economics from "./Economics";

export default {
  // eslint-disable-next-line
  name: "TableExtension",
  props: ["item"],
  components: { TechnicalSpecs, Category, Economics },
  data: function () {
    return {
      image: this.item.details.image,
      dialogue: false,
      opened: 0,
    };
  },
  computed: {
    width() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return window.innerWidth;
        case "sm":
          return window.innerWidth;
        default:
          return "100%";
      }
    },
  },
  watch: {
    item: {
      handler() {
        this.image = this.item.details.image;
      },
    },
  },
  methods: {
    onImageError() {
      this.image = false;
    },
  },
};
</script>

<style scoped>
.col {
  padding: 0 !important;
}

#pdf {
  text-align: center;
}

div.v-expansion-panels {
  border-radius: 0 !important;
}

.icon {
  order: 0;
  padding: 0 16px;
}

.header {
  order: 1;
}

.v-expansion-panel-header {
  font-weight: bold;
}

::v-deep .v-list-item__subtitle {
  color: black !important;
}

#preview {
  max-width: 750px;
  justify-content: end;
}

.preview {
  font-size: 1rem;
}

::v-deep .v-icon {
  color: black !important;
}
</style>
