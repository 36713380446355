<template>
  <v-container>
    <v-row style="min-width: 350px">
      <v-col v-for="(data, subcategory) in data" :key="subcategory">
        <v-card-text>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{
                startCase(subcategory)
              }}</v-list-item-title>
              <v-list-item-content v-for="(value, field) in data" :key="field">
                {{ startCase(field) }}: {{ value }}
              </v-list-item-content>
            </v-list-item-content>
          </v-list-item>
        </v-card-text>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import startCase from "lodash.startcase";

export default {
  // eslint-disable-next-line
  name: "Category",
  props: ["data", "category"],
  data: () => ({
    startCase: startCase,
  }),
};
</script>

<style scoped>
::v-deep .v-card__text {
  padding: 0;
}

.v-list-item__title {
  font-weight: 475 !important;
  font-size: 0.875rem !important;
  padding: 0.25rem 0 !important;
}

.v-list-item__content {
  padding: 0 !important;
}

.expanded-content {
  background-color: rgba(141, 195, 64, 0.35) !important;
}

.expanded-content tr:hover {
  background-color: transparent !important;
}
</style>
