<template>
  <v-container>
    <v-row>
      <v-col style="padding: 0.75rem 0"
        ><p>
          Search for hydropower technologies by filling out the form:
        </p></v-col
      >
    </v-row>
    <v-form ref="form">
      <v-row id="form">
        <v-col style="border-right: 1px solid black">
          <v-row>
            <b>1. Select at least one technology Application (Required)</b>
          </v-row>
          <br /><br />
          <v-row>
            <v-col style="min-width: 215px">
              <v-row
                ><h4>
                  Application
                  <div style="color: red; display: inline">*</div>
                </h4></v-row
              >
              <v-row style="padding-bottom: 1rem"
                ><v-select
                  v-model="$store.getters['form'].application"
                  :items="applications"
                  item-text="code"
                  item-value="code"
                  :rules="[...rules.required]"
                  label="Choose one or more Applications"
                  multiple
                  chips
                  item-color="#07519E"
                  hint="Application"
                  persistent-hint
                  color="#07519E"
                ></v-select
              ></v-row>
            </v-col>
          </v-row>
          <v-row><h5>Definitions:</h5></v-row>
          <template v-if="applications">
            <v-row>
              <v-list :dense="true">
                <v-row
                  :dense="true"
                  v-for="app in applications"
                  :key="app.code"
                  :label="app.code"
                  :value="app.code"
                >
                  <v-col>
                    {{ app.code }}
                    <v-tooltip right max-width="250">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <v-icon small> mdi-help-circle-outline </v-icon>
                        </v-btn>
                      </template>
                      <span>
                        {{ app.definition }}
                      </span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-list>
            </v-row>
          </template>
        </v-col>
        <v-col style="border-right: 1px solid black">
          <v-row>
            <b>2. Refine results by selecting technology Types (Optional)</b>
          </v-row>
          <br /><br />
          <v-row><h4>Type</h4></v-row>
          <v-row
            ><v-col style="min-width: 175px">
              <v-row style="padding-bottom: 1rem"
                ><v-select
                  v-model="$store.getters['form'].type"
                  :items="types"
                  item-text="name"
                  item-value="name"
                  label="Specify technology Types"
                  multiple
                  chips
                  item-color="#07519E"
                  hint="Type"
                  persistent-hint
                  color="#07519E"
                ></v-select
              ></v-row> </v-col
          ></v-row>
          <v-row><h5>Definitions:</h5></v-row>
          <template v-if="types">
            <v-row>
              <v-list :dense="true">
                <v-row
                  :dense="true"
                  v-for="type in types"
                  :key="type.name"
                  :label="type.name"
                  :value="type.name"
                >
                  <v-col>
                    {{ type.name }}
                    <v-tooltip right max-width="250">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <v-icon small> mdi-help-circle-outline </v-icon>
                        </v-btn>
                      </template>
                      <span>
                        {{ type.definition }}
                      </span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-list>
            </v-row>
          </template>
        </v-col>
        <v-col>
          <v-row>
            <b
              >3. Enter the desired technology Operational Parameters
              (Optional)</b
            >
          </v-row>
          <br /><br />
          <v-row><h5>Guidance for this section:</h5></v-row>
          <v-row
            ><p style="font-size: 0.75rem">
              1. These parameters are <i>optional</i>.
              <br />
              2. The database will return any technology that matches at least
              <i>one</i> parameter range.
              <br />
              3. If an operational parameter is not yet known, it is included in
              the query result.
              <br />
              4. When searching for hydrokinetic technologies, the change in
              units is accounted for.
            </p></v-row
          >
          <v-row>
            <v-col>
              <h4>Power Rating (kW)</h4>
              <v-text-field
                style="min-width: 175px"
                type="number"
                height="25"
                label="Minimum Power Rating"
                hint="kW"
                color="#07519E"
                v-model="$store.getters['form'].power_rating_min"
              >
              </v-text-field>
              <v-text-field
                style="min-width: 175px"
                type="number"
                height="25"
                label="Maximum Power Rating"
                hint="kW"
                color="#07519E"
                v-model="$store.getters['form'].power_rating_max"
              >
              </v-text-field>

              <template
                v-if="
                  $store.getters['form'].type &&
                  $store.getters['form'].type.includes('Hydrokinetic')
                "
              >
                <h4>Head or Water Depth (ft)</h4>
                <v-text-field
                  style="min-width: 175px"
                  type="number"
                  height="25"
                  label="Minimum Depth"
                  hint="ft"
                  color="#07519E"
                  v-model="$store.getters['form'].head_min"
                >
                </v-text-field>
                <v-text-field
                  style="min-width: 175px"
                  type="number"
                  height="25"
                  label="Maximum Depth"
                  hint="ft"
                  color="#07519E"
                  v-model="$store.getters['form'].head_max"
                >
                </v-text-field>
              </template>
              <template v-else>
                <h4>Head (ft)</h4>
                <v-text-field
                  style="min-width: 175px"
                  type="number"
                  height="25"
                  label="Minimum Head"
                  hint="ft"
                  color="#07519E"
                  v-model="$store.getters['form'].head_min"
                >
                </v-text-field>
                <v-text-field
                  style="min-width: 175px"
                  type="number"
                  height="25"
                  label="Maximum Head"
                  hint="ft"
                  color="#07519E"
                  v-model="$store.getters['form'].head_max"
                >
                </v-text-field>
              </template>
            </v-col>

            <v-col>
              <template
                v-if="
                  $store.getters['form'].type &&
                  $store.getters['form'].type.includes('Hydrokinetic')
                "
              >
                <h4>Flow (cfs) or Flow Rate (ft/s)</h4>
                <v-text-field
                  style="min-width: 175px"
                  type="number"
                  height="25"
                  label="Minimum Flow Rate"
                  hint="ft/s"
                  color="#07519E"
                  v-model="$store.getters['form'].flow_min"
                >
                </v-text-field>
                <v-text-field
                  style="min-width: 175px"
                  type="number"
                  height="25"
                  label="Maximum Flow Rate"
                  hint="ft/s"
                  color="#07519E"
                  v-model="$store.getters['form'].flow_max"
                >
                </v-text-field>
              </template>
              <template v-else>
                <h4>Flow (cfs)</h4>
                <v-text-field
                  style="min-width: 175px"
                  type="number"
                  height="25"
                  label="Minimum Flow"
                  hint="cfs"
                  color="#07519E"
                  v-model="$store.getters['form'].flow_min"
                >
                </v-text-field>
                <v-text-field
                  style="min-width: 175px"
                  type="number"
                  height="25"
                  label="Maximum Flow"
                  hint="cfs"
                  color="#07519E"
                  v-model="$store.getters['form'].flow_max"
                >
                </v-text-field>
              </template>

              <h4>Voltage (V)</h4>
              <v-text-field
                style="min-width: 175px"
                type="number"
                height="25"
                label="Voltage"
                hint="Volts"
                color="#07519E"
                v-model="$store.getters['form'].voltage"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row style="border-bottom: 1px solid black">
        <v-col class="text-right"
          ><v-btn @click="search()" style="background-color: #2ba8e0"
            >Search</v-btn
          ></v-col
        >
      </v-row>
      <v-row>
        <v-col>
          <v-expansion-panels flat>
            <v-expansion-panel>
              <v-expansion-panel-header class="aligned"
                >Export</v-expansion-panel-header
              >
              <v-expansion-panel-content
                ><v-row
                  ><v-col>
                    <v-tooltip left max-width="250px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          small
                          @click="xls('query')"
                          style="background-color: #2ba8e0"
                          >Export Query</v-btn
                        >
                      </template>
                      <span
                        >Export technology matching the provided search
                        parameters</span
                      ></v-tooltip
                    >
                  </v-col></v-row
                >
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-form>
    <v-row v-if="error">
      <v-col>
        {{ error }}
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import FileSaver from "file-saver";

export default {
  // eslint-disable-next-line
  name: "TechForm",
  data: () => ({
    rules: {
      required: [(v) => !!v || "Required"],
    },
    types: [],
    applications: [],
    error: null,
  }),
  methods: {
    async search() {
      if (this.$refs.form.validate()) {
        await axios
          .post(
            "django/tech/catalogue",
            JSON.stringify(this.$store.getters["form"])
          )
          .then((response) => {
            this.$emit("panel");
            this.$store.commit("technologies", response.data.technologies);
          })
          .catch((error) => {
            this.error = error.response.data.message;
          });
      }
    },
    async xls(query_type) {
      if (query_type === "query" && this.$refs.form.validate()) {
        await axios
          .post(
            `django/tech/xls`,
            JSON.stringify(this.$store.getters["form"]),
            { responseType: "blob" }
          )
          .then((response) => {
            FileSaver.saveAs(response.data, "hydro-technology-query");
          });
      }
    },
  },
  mounted: async function () {
    this.types = await axios
      .get("django/tech/types")
      .then((response) => {
        console.log(response.data.types);
        return response.data.types;
      })
      .catch((error) => {
        console.log(error);
      });
    this.applications = await axios
      .get("django/tech/applications")
      .then((response) => {
        return response.data.applications;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style scoped>
#form > .col {
  padding: 1rem 2rem;
}

.v-expansion-panels {
  justify-content: end;
}

::v-deep .v-icon {
  color: #2ba8e0 !important;
}

.v-expansion-panel {
  max-width: 185px !important;
}
</style>
